import React, { Fragment } from "react";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import styled from "styled-components";

const Container = styled.div`
  text-align: center;
  font-family: "Open Sans";
  font-weight: 400;
  font-size: 15px;
  padding: 17px;
  margin: 3vw auto;
`;

const InstagramLogo = styled.img`
width: 26px;
height 26px;
`;

const Footer = () => {
  const matches = useMediaQuery("(min-width:600px)");
  return (
    <Container>
      <Fragment>
        <a href="https://instagram.com/fotograf_anne">
          <InstagramLogo
            src={require("../../assets/instagram-sketched.png")}
            alt="Instagram"
          />
        </a>
        <p>FOTOGRAF ANNE ANDERSEN</p>
        {matches ? (
          <p>
            telefon: 905 56 916 | mail:{" "}
            <a href="mailto:anne@anneandersen.no">anne@anneandersen.no</a>
          </p>
        ) : (
          <p>
            telefon: 905 56 916
            <br />
            <a href="mailto:anne@anneandersen.no">anne@anneandersen.no</a>
          </p>
        )}
      </Fragment>
    </Container>
  );
};

export default Footer;
