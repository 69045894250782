import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Link as RouterLink } from "react-router-dom";
import FolderIcon from "@material-ui/icons/Folder";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import styled from "styled-components";

import GalleryImage from "./GalleryImage";
import GalleryGrid from "./GalleryGrid";
import Share from "./Share";
import GalleryContainer from "./GalleryContainer";
import DownloadAllButtonContainer from "./DownloadAllButton";
import { Heading } from "./components";
import MaterialButton from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { getThumbnailUrl, getFullSizeUrl } from "../utils";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, analytics } from "../utils/firebase";
import PropTypes from "prop-types";
import { logEvent } from "firebase/analytics";

const getImagesFromList = (list) => {
  return list.filter((entry) => !entry.endsWith("/"));
};

const getFoldersFromList = (list) => {
  return list.filter((entry) => entry.endsWith("/"));
};

const getLinks = async (url) => {
  const result = await fetch(url);
  const html = await result.text();
  const links = html.match(/href="([^"]*")/g);
  return links.map((link) => link.replace('href="', "").replace('"', ""));
};

const isParentFolder = (path, folder) => {
  const pathComponents = path.split("/");
  const parentPath = pathComponents.slice(-2)[0];
  return parentPath === folder.split("/").join("") || folder === "/";
};

const downloadAll = (path, onComplete, onError) => {
  const url =
    "https://download.anneandersen.no/zipFolder.php?path=" + path + "/";
  fetch(url)
    .then(async (response) => {
      const json = await response.json();
      let url = json.downloadUrl;
      let a = document.createElement("a");
      a.href = url;
      a.download = "bilder.zip";
      a.click();
      onComplete();
    })
    .catch(onError);
};

const HeadingContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
`;

const StyledLoading = styled(CircularProgress)`
  padding: 20px;
`;

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const Loading = () => (
  <LoadingContainer>
    <StyledLoading />
  </LoadingContainer>
);

function Gallery(props) {
  const { path = "", shareId = "" } = useParams();
  const [images, setImages] = useState([]);
  const [folders, setFolders] = useState([]);
  const [isDownloading, setIsDownloading] = useState(false);
  const [user] = useAuthState(auth);
  const isShare = shareId !== "";
  const isRoot = path === "";
  const [errorMessage, setErrorMessage] = useState();
  const [imagePath, setImagePath] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (shareId) {
      logEvent(analytics, "view_with_shareId");
      setIsLoading(true);
      fetch(
        `https://us-central1-anne-andersen-foto.cloudfunctions.net/app/verify/${shareId}`
      )
        .then((result) => result.json())
        .then((json) => {
          if (json.images) {
            const linkPath = json.images + (path === "" ? "" : "/" + path);

            getLinks("https://bilder.anneandersen.no/" + linkPath + "/").then(
              (links) => {
                const images = getImagesFromList(links);
                const folders = getFoldersFromList(links);
                setImages(images);
                setFolders(folders);
                setImagePath(linkPath);
                setIsLoading(false);
              }
            );
          } else if (json.message) {
            setIsLoading(false);
            throw new Error(json.message);
          }
        })
        .catch((error) => {
          setErrorMessage(error.message);
          setIsLoading(false);
        });
    } else if (user) {
      logEvent(analytics, "view_with_user");
      setIsLoading(true);
      getLinks("https://bilder.anneandersen.no/" + path + "/")
        .then((links) => {
          const images = getImagesFromList(links);
          const folders = getFoldersFromList(links);
          setImages(images);
          setFolders(folders);
          setImagePath(path);
          setIsLoading(false);
        })
        .catch((error) => {
          console.error(error);
          setIsLoading(false);
        });
    }
  }, [path, props.images, shareId, user]);

  return (
    <GalleryContainer>
      <HeadingContainer>
        <Heading>
          {imagePath.split("_").join(" ").split("/").join(" | ")}
        </Heading>
        {!isShare && !isRoot && <Share images={imagePath} />}
      </HeadingContainer>
      {folders.map((folder) => {
        const isParent = isParentFolder(imagePath, folder);
        if (isParent && isShare) {
          return null;
        }

        const FolderIconComponent = isParent ? ArrowUpwardIcon : FolderIcon;
        const folderLink = shareId
          ? `/collections/${shareId}/${folder}`
          : folder;

        return (
          <p key={folder}>
            <MaterialButton
              startIcon={
                <FolderIconComponent fontSize="medium" color="primary" />
              }
              color="primary"
              onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
              component={RouterLink}
              to={folderLink}
            >
              {isParent
                ? "Overordnet mappe"
                : folder.split("/").join("").split("_").join(" ")}
            </MaterialButton>
          </p>
        );
      })}
      {images.length > 0 && (
        <DownloadAllButtonContainer>
          <MaterialButton
            startIcon={<CloudDownloadIcon />}
            disabled={isDownloading}
            color="primary"
            variant="contained"
            onClick={() => {
              logEvent(analytics, "download_all_clicked");
              setIsDownloading(true);
              downloadAll(
                imagePath,
                () => {
                  setIsDownloading(false);
                },
                (error) => {
                  setIsDownloading(false);
                  alert(
                    "Filene var for store til å laste ned fra websiden. Vennligst kontakt Anne Andersen for å få tilsendt alle bildene."
                  );
                }
              );
            }}
          >
            {isDownloading ? "Nedlastingen begynner snart..." : "Last ned alle"}
          </MaterialButton>
        </DownloadAllButtonContainer>
      )}
      <center>
        <h4>{errorMessage}</h4>
      </center>
      {!isLoading && (
        <GalleryGrid>
          {images.map((image, index) => {
            return (
              <GalleryImage
                key={image}
                src={getThumbnailUrl(image, imagePath)}
                fullSizeUrl={getFullSizeUrl(image, imagePath)}
              />
            );
          })}
        </GalleryGrid>
      )}
      {isLoading && <Loading />}
    </GalleryContainer>
  );
}

Gallery.propTypes = {
  share: PropTypes.bool,
};
Gallery.defaultProps = { share: false };

export default Gallery;
