import React, { Component } from "react";
import PropTypes from "prop-types";

import { Image, ImageContainer } from "./components";

class GalleryImage extends Component {
  render() {
    return (
      <ImageContainer>
        <a href={this.props.fullSizeUrl}>
          <Image src={this.props.src} aspectRatio={840 / 567} />
        </a>
      </ImageContainer>
    );
  }
}

GalleryImage.propTypes = {
  src: PropTypes.node.isRequired,
  fullSizeUrl: PropTypes.string,
};
GalleryImage.defaultProps = {};

export default GalleryImage;
