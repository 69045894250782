import styled from "styled-components";

export default styled.div`
  text-align: left;
  font-family: "Chivo", sans-serif;
  font-size: 22px;
  @media (max-width: 960px) {
    font-size: 16px;
  }
  font-weight: 400;
`;
