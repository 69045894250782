import React, { useState } from "react";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import styled from "styled-components";
import {
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
} from "firebase/auth";
import { auth } from "../../utils/firebase";

const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  font-family: "Open Sans";
  font-weight: 400;
  font-size: 15px;
`;

const StyledTextField = styled(TextField)`
  margin: 20px;
  height: 70px;
`;

const FormElements = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  justify-content: space-around;
`;

const ErrorMessage = styled.div`
  color: red;
  margin: 20px;
`;

const Login = () => {
  const [userName, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState(null);
  const [disableForgotEmail, setDisableForgotEmail] = useState(false);
  const [
    forgotPasswordEmailMessage,
    setForgotPasswordEmailMessage,
  ] = useState();

  const forgotPassword = async () => {
    setDisableForgotEmail(true);
    try {
      await sendPasswordResetEmail(auth, userName);
      setForgotPasswordEmailMessage("E-post er sendt.");
    } catch (error) {
      setForgotPasswordEmailMessage("Klarte ikke å sende e-post.");
      console.error(error);
    }
  };

  return (
    <form
      onSubmit={(event) => {
        signInWithEmailAndPassword(auth, userName, password).catch((error) => {
          if (error.code === "auth/invalid-email") {
            setErrorMessage("Fant ikke e-postaddressen.");
          } else if (error.code === "auth/wrong-password") {
            setErrorMessage("Feil passord.");
          } else if (error.code === "auth/too-many-requests") {
            setErrorMessage(
              "Du har brukt for mange innloggingsforsøk. Trykk på glemt passord!"
            );
          } else {
            setErrorMessage(null);
          }
        });
        event.preventDefault();
      }}
    >
      <Container>
        <h1>Logg inn</h1>
        {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
        <FormElements>
          <StyledTextField
            label="E-post"
            type="username"
            variant="outlined"
            onChange={(event) => setUsername(event.target.value)}
          />
          <StyledTextField
            label="Passord"
            type="password"
            variant="outlined"
            onChange={(event) => setPassword(event.target.value)}
          />
        </FormElements>
        <Button
          type="submit"
          variant="outlined"
          size="large"
          disabled={!userName || !password}
        >
          Logg inn
        </Button>
        <p />
        <Button
          onClick={forgotPassword}
          disabled={!userName || disableForgotEmail}
        >
          Glemt passord?
        </Button>
        {forgotPasswordEmailMessage}
      </Container>
    </form>
  );
};

export default Login;
